@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  a {
    @apply text-blue-600;
  }
  body {
    @apply bg-black text-white font-mono;
  }
  input, textarea, select {
    @apply bg-gray-700 w-1/2 block my-2;
  }
  @font-face {
    font-family: 'silkscreen';
    src: url("./slkscr.ttf");
  }
}

@layer components {
  .nav-neon {
    @apply col-span-6 md:col-span-3 text-center text-2xl text-neon text-white hover:text-neon-white bg-neutral-900 hover:bg-neutral-700 p-2 m-2 rounded-md
  }
  .eightbit-inner {
    @apply text-center text-white bg-neutral-900 hover:bg-red-400 p-2 -mx-3 border-x-4 border-red-400 font-pixel
  }
  .eightbit-outer {
    @apply p-2 border-y-4 border-red-400 hover:bg-red-400
  }
  .content-box {
    @apply col-span-6 md:col-span-3 text-left bg-neutral-800 p-6 m-4 rounded-md space-y-2 border-2 border-red-400 shadow-red-500 shadow-neon break-words 
  }
}
@layer utilities {
  @variants responsive {
      .text-neon {
        text-shadow:     0 0 3px #fff,
        0 0 5px #fff,
        0 0 10px #f00,
        0 0 20px #f00,
        0 0 40px #f00
      }
      .text-neon-white {
        text-shadow:     0 0 3px #fff,
        0 0 5px #fff,
        0 0 10px #fff,
        0 0 20px #fff,
        0 0 40px #fff
      }
      .text-shadow-none {
        text-shadow: none;
      }
  } 
}